"use client";

import { allColors } from "@/providers/theme/theme-data";
import {
  ThemeColorActionType,
  useThemeArea,
  useThemesDispatch,
} from "@/providers/theme/theme-provider";
import { Button, SelectItem } from "@lifizy/ui";
import { useEffect, useState, type ReactNode } from "react";
import { MdDarkMode, MdOutlineDarkMode } from "react-icons/md";

import SheetSelector from "./sheet-selector";

export default function ThemeColorForm({ onClose }: { onClose: () => void }): ReactNode {
  const dispatchTheme = useThemesDispatch();
  const themeArea = useThemeArea("web");
  const [color, setColor] = useState(allColors[0]);

  useEffect(() => {
    const initColor = allColors.find((t) => t.code === themeArea.theme.colorCode);
    if (initColor) setColor(initColor);
  }, [themeArea]);
  const nextColor = () => {
    const index = allColors.indexOf(color);
    const action: ThemeColorActionType = {
      type: "colorChange",
      themeAreaCode: themeArea.code,
      colorCode: "",
    };
    if (index < allColors.length - 1) {
      setColor(allColors[index + 1]);
      action.colorCode = allColors[index + 1].code;
    } else {
      setColor(allColors[0]);
      action.colorCode = allColors[0].code;
    }
    dispatchTheme(action);
  };
  const previousColor = () => {
    const index = allColors.indexOf(color);
    const action: ThemeColorActionType = {
      type: "colorChange",
      themeAreaCode: themeArea.code,
      colorCode: "",
    };
    if (index > 0) {
      setColor(allColors[index - 1]);
      action.colorCode = allColors[index - 1].code;
    } else {
      setColor(allColors[allColors.length - 1]);
      action.colorCode = allColors[allColors.length - 1].code;
    }
    dispatchTheme(action);
  };
  const onValueChange = (code) => {
    const newColor = allColors.find((t) => t.code === code);
    if (!newColor) return;
    const action: ThemeColorActionType = {
      type: "colorChange",
      themeAreaCode: themeArea.code,
      colorCode: code,
    };
    setColor(newColor);
    dispatchTheme(action);
  };
  const active = themeArea.theme.modes.includes("dark");
  return (
    <SheetSelector
      value={color.code}
      label="Modifiez la couleur principale"
      onValueChange={onValueChange}
      onNext={nextColor}
      onPrevious={previousColor}
      onClose={onClose}
      actionButton={
        <Button
          variant="ghost"
          size="sm"
          className={`text-editor-text  hover:bg-editor-button-active/40 `}
          onClick={() => {
            dispatchTheme({
              type: active ? "modeOff" : "modeOn",
              mode: "dark",
              themeAreaCode: themeArea.code,
            });
          }}>
          {active ? <MdDarkMode className="w-8 h-8" /> : <MdOutlineDarkMode className="w-8 h-8" />}
        </Button>
      }>
      {allColors.map(({ code, color, label }) => (
        <SelectItem key={code} value={code}>
          {label}
          <svg
            style={{ color, display: "inline-block", marginLeft: "2px" }}
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              clipRule="evenodd"
              d="M3 9C3 6.5 4.5 4.25 7.5 1.5C10.5 4.25 12 6.5 12 9C12 11.4853 9.98528 13.5 7.5 13.5C5.01472 13.5 3 11.4853 3 9ZM10.9524 8.30307C9.67347 7.82121 8.2879 8.46208 6.98956 9.06259C5.9327 9.55142 4.93365 10.0135 4.09695 9.82153C4.03357 9.55804 4 9.28294 4 9C4 7.11203 5.02686 5.27195 7.5 2.87357C9.66837 4.97639 10.725 6.65004 10.9524 8.30307Z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </svg>
        </SelectItem>
      ))}
    </SheetSelector>
  );
}
