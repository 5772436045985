"use client";
import Link from "next/link";

export default function WebMenu() {
  function onClose() {
    document.getElementById("mobile-menu")?.click();
  }
  return (
    <ul
      className="flex flex-col mt-4 font-medium text-2xl pl-5 md:flex-row md:space-x-8 md:mt-0 md:text-base md:pl-0"
      onClick={onClose}>
      <li>
        <Link className="block py-2 pr-4 pl-3 rounded md:p-0" href="/">
          Accueil
        </Link>
      </li>
      <li>
        <Link className="block py-2 pr-4 pl-3 rounded md:p-0" href="/menu">
          Notre menu
        </Link>
        <ul className="lg:hidden">
          <li>
            <Link className="block py-2 pr-4 pl-8 rounded md:p-0 text-base" href="/menu#pizza">
              Nos pizzas
            </Link>
          </li>
          <li>
            <Link className="block py-2 pr-4 pl-8 rounded md:p-0 text-base" href="/menu#pate">
              Nos pâtes
            </Link>
          </li>
          <li>
            <Link className="block py-2 pr-4 pl-8 rounded md:p-0 text-base" href="/menu#dessert">
              Nos desserts
            </Link>
          </li>
        </ul>
      </li>
      <li>
        <Link className="block py-2 pr-4 pl-3 rounded md:p-0" href="/restaurant">
          Le restaurant
        </Link>
      </li>
      <li>
        <Link className="block py-2 pr-4 pl-3 rounded md:p-0" href="/contact">
          Nous contacter
        </Link>
      </li>
      {/* <li>
          <Link className="block py-2 pr-4 pl-3 rounded md:p-0" href="/contact">Contact</Link>
      </li> */}
    </ul>
  );
}
