"use client";

import BasicContentEditable from "@/components/edit/basic-content-editable";
import OptionView from "@/components/menu/item/choice/option-view";
import { useEditUiContext } from "@/providers/edit/edit-ui-provider";
import { useWebDataContext } from "@/providers/web-data/web-data-provider";
import { setItemChoiceAction } from "@/server/actions/action-menu";
import { ItemChoiceTreeType } from "@lifizy/domain-next";
import {
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@lifizy/ui";
import { nanoid } from "nanoid";
import { useEffect, useState, type ReactNode } from "react";
import { FcDataConfiguration } from "react-icons/fc";
import { HiOutlineTrash } from "react-icons/hi";

export default function ChoiceForm({
  onClose,
  newChoice,
}: {
  onClose: () => void;
  newChoice: ItemChoiceTreeType;
}): ReactNode {
  const [display, setDisplay] = useState("unique");
  const [cols, setCols] = useState(3);
  const [choice, setChoice] = useState<ItemChoiceTreeType>(newChoice);
  const { getItemChoiceGroup } = useWebDataContext();
  const { webAdminForm, active } = useEditUiContext();
  useEffect(() => {
    if (!choice) return;
    if (display === "multiple") {
      setCols(1);
      return;
    }
    if (choice.options.length === 2 || choice.options.length === 4) {
      setCols(2);
      return;
    }
    setCols(3);
  }, [choice, display]);

  const addChoice = () => {
    setChoice({
      ...choice,
      options: [
        ...choice.options,
        { id: nanoid(), title: "Option", item: { id: nanoid(), title: "Option", ingredients: [] } },
      ],
    });
  };
  const onSave = async () => {
    const choiceGroup = getItemChoiceGroup(null);

    if (!choiceGroup) return;
    await setItemChoiceAction(
      { ...choice, order: choiceGroup.choices.length },
      choiceGroup.id,
      choiceGroup.groupId
    );
    onClose();
  };
  const onRemove = (optionId: string) => {
    setChoice({
      ...choice,
      options: choice.options.filter((o) => o.id !== optionId),
    });
  };
  return (
    <Drawer
      open={true}
      onOpenChange={(state: boolean) => {
        if (!state) onClose();
      }}>
      <DrawerContent>
        <DrawerHeader>
          <div className="">
            {/* <div className="text-xl">{item.title}</div> */}
            <div className="text pb-1 pt-3 relative">
              <BasicContentEditable
                html={choice.title!}
                onChange={(value) => {
                  setChoice({ ...choice, title: value });
                }}
                editOn={true}
              />
              {active && webAdminForm === "menu" && (
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <Button variant="app" size="sm" className="absolute top-0 right-0 p-2">
                      <FcDataConfiguration className="w-5 h-5" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="bg-editor-background text-editor-text font-sans">
                    <DropdownMenuLabel>Configuration de l&apos;étape</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuRadioGroup value={display} onValueChange={setDisplay}>
                      <DropdownMenuRadioItem value="unique">
                        Un choix parmi plusieurs
                      </DropdownMenuRadioItem>
                      <DropdownMenuRadioItem value="multiple">
                        Des choix multiples
                      </DropdownMenuRadioItem>
                    </DropdownMenuRadioGroup>

                    <DropdownMenuSeparator />
                    <DropdownMenuItem onClick={() => {}}>
                      <HiOutlineTrash className="mr-2" /> Supprimer
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
            </div>
            <div
              className={`pt-3 cursor-pointer grid ${cols === 1 ? "grid-cols-1" : cols === 2 ? "grid-cols-2" : "grid-cols-3"} gap-1`}>
              {choice.options.map((c) => (
                <OptionView
                  key={c.id}
                  choice={choice}
                  img={""}
                  option={c}
                  onNext={() => {}}
                  multi={display === "multiple"}
                  onRemove={onRemove}
                  onChange={(option) => {
                    setChoice({
                      ...choice,
                      options: choice.options.map((o) => (o.id === option.id ? option : o)),
                    });
                  }}
                />
              ))}
            </div>
            <Button
              variant="app"
              size="xs"
              className="mt-2 px-3 text-xs rounded-sm"
              onClick={addChoice}>
              Ajouter une option
            </Button>
          </div>
        </DrawerHeader>
        <DrawerFooter className="pt-1">
          <div className="flex justify-between">
            <Button variant="outline" onClick={onClose}>
              Annuler
            </Button>
            <Button variant="outline" onClick={onSave}>
              Valider
            </Button>
          </div>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
