"use client";
import { Button } from "@lifizy/ui";
import { modeAdminAction, saveGroupAuthRequestAction, signOutAction } from "./action";

export function SignIn() {
  const submit = async () => {
    const authReq = await saveGroupAuthRequestAction();
    console.log("authReq", authReq);
    if (authReq.length > 0) window.location.assign("http://localhost:3002/" + authReq[0].id);
  };

  return (
    <Button className="w-full p-0" onClick={submit}>
      Se connecter
    </Button>
  );
}
// export function SignIn() {

//   return (
//     <form action={saveGroupAuthRequestAction}>
//       <Button className="w-full p-0" type="submit">
//         Se connecter
//       </Button>
//     </form>
//   );
// }

export function ModeAdmin() {
  const submit = async () => {
    await modeAdminAction();
  };

  return (
    <Button className="w-full p-0" onClick={submit}>
      Mode Admin
    </Button>
  );
}

export function SignOut(props: React.ComponentPropsWithRef<typeof Button>) {
  return (
    <form action={signOutAction} className="w-full">
      <Button className="w-full p-0" {...props}>
        Se déconnecter
      </Button>
    </form>
  );
}
