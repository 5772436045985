"use client";

import { allFonts, fonts } from "@/providers/theme/theme-data";
import {
  ThemeFontActionType,
  useThemeArea,
  useThemesDispatch,
} from "@/providers/theme/theme-provider";
import { SelectItem } from "@lifizy/ui";
import { useState, type ReactNode } from "react";
import { useEditUiDispatch } from "../../../providers/edit/edit-ui-provider";
import SheetSelector from "./sheet-selector";
export default function ThemeFontForm({ onClose }: { onClose: () => void }): ReactNode {
  const dispatchTheme = useThemesDispatch();
  const dispatchUi = useEditUiDispatch();
  const themeArea = useThemeArea("web");
  const [font, setFont] = useState(allFonts[0]);

  if (!themeArea || !dispatchTheme || !dispatchUi) return null;

  const nextFont = () => {
    const index = allFonts.indexOf(font);
    const action: ThemeFontActionType = {
      type: "fontChange",
      themeAreaCode: themeArea.code,
      font: "",
    };
    if (index < allFonts.length - 1) {
      setFont(allFonts[index + 1]);
      action.font = allFonts[index + 1].value;
    } else {
      setFont(allFonts[0]);
      action.font = allFonts[0].value;
    }
    dispatchTheme(action);
  };
  const previousFont = () => {
    const index = allFonts.indexOf(font);
    const action: ThemeFontActionType = {
      type: "fontChange",
      themeAreaCode: themeArea.code,
      font: "",
    };
    if (index > 0) {
      setFont(allFonts[index - 1]);
      action.font = allFonts[index - 1].value;
    } else {
      setFont(allFonts[allFonts.length - 1]);
      action.font = allFonts[allFonts.length - 1].value;
    }
    dispatchTheme(action);
  };
  const onValueChange = (value) => {
    const newFont = allFonts.find((f) => f.value === value);
    if (!newFont) return;
    const action: ThemeFontActionType = {
      type: "fontChange",
      themeAreaCode: themeArea.code,
      font: value,
    };
    setFont(newFont);
    dispatchTheme(action);
  };
  return (
    <SheetSelector
      value={font.value}
      label="Sélectionnez une police de caractère"
      onValueChange={onValueChange}
      onNext={nextFont}
      onPrevious={previousFont}
      onClose={onClose}>
      {allFonts.map(({ value, label }) => (
        <SelectItem key={value} value={value} className={fonts[value].className}>
          {label}
        </SelectItem>
      ))}
    </SheetSelector>
  );
}
