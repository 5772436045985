"use client";
/* eslint-disable @typescript-eslint/no-unsafe-assignment -- temp*/
/* eslint-disable @typescript-eslint/explicit-function-return-type -- temporary */
/* eslint-disable @typescript-eslint/no-empty-function -- temporary */
import clsx from "clsx";
import { useState } from "react";

import { fonts } from "@/providers/theme/theme-data";
import { useEditUiDispatch } from "../../providers/edit/edit-ui-provider";
import BlockManager from "./block-manager";
import AdminBar from "./forms/admin-bar";

function Icon({ path = "options", className = "w-4 h-4" }) {
  return (
    <img src={`https://assets.codepen.io/3685267/${path}.svg`} alt="" className={clsx(className)} />
  );
}

// function Image({ path = "1", className = "w-4 h-4" }) {
//   return (
//     <img
//       src={`https://assets.codepen.io/3685267/${path}.jpg`}
//       alt=""
//       className={clsx(className, "rounded-full")}
//     />
//   );
// }
// const sidebarItems = [
//   [
//     { id: '0', title: 'Dashboard', notifications: false },
//     { id: '1', title: 'Overview', notifications: false },
//     { id: '2', title: 'Chat', notifications: 6 },
//     { id: '3', title: 'Team', notifications: false },
//   ],
//   [
//     { id: '4', title: 'Tasks', notifications: false },
//     { id: '5', title: 'Reports', notifications: false },
//     { id: '6', title: 'Settings', notifications: false },
//   ],
// ];
export function Sidebar({ editOn }: { editOn: boolean }) {
  const [selected, setSelected] = useState("0");
  const [label, setLabel] = useState("Thème du site");
  const dispatchEditUi = useEditUiDispatch();
  if (!dispatchEditUi) return <>Error no dispatch</>;
  return (
    <div
      id="sidebar"
      className={clsx(
        // 'fixed inset-y-0 left-0 bg-card w-full sm:w-20 xl:w-60 sm:flex flex-col z-10',
        "theme-blue dark bg-editor text-editor-text z-[1000] fixed bottom-0 left-0 right-0 lg:relative lg:w-60 xl:w-80 flex flex-shrink-0 lg:flex-col justify-between ",
        fonts.inter.className
      )}>
      {/* <div className="flex-shrink-0 overflow-hidden p-2">
        <div className="flex items-center h-full sm:justify-center xl:justify-start p-2 sidebar-separator-top">
          <IconButton icon="res-react-dash-logo" className="w-10 h-10" />
          <div className="block ml-2 font-bold text-xl text-white">
            Site web
          </div>
          <div className="flex-grow sm:hidden xl:block" />
          <IconButton
            icon="res-react-dash-sidebar-close"
            className="block sm:hidden"
            onClick={() => { setShowSidebar(false); }}
          />
        </div>
      </div> */}
      {editOn && (
        <div className="my-auto lg:hidden w-full">
          <AdminBar />
        </div>
      )}
      <div className="hidden lg:flex flex-grow overflow-x-hidden overflow-y-auto flex-col">
        <div className="w-full p-3 h-24 lg:h-20 xl:h-24 flex-shrink-0">
          <button
            type="button"
            onClick={() => {
              if (selected === "0") {
                setSelected("1");
                setLabel("Mise en page");
              } else {
                setSelected("0");
                setLabel("Thème du site");
              }
            }}
            className="bg-slate-800 rounded-xl w-full h-full flex items-center px-2 lg:px-3">
            <Icon path="res-react-dash-sidebar-card" className="w-9 h-9 " />
            <div className="pl-3 text-left">
              <div className="font-bold">{label}</div>
              <div className="text-xs">
                {selected === "0" ? "Design global" : "Gestion des blocks"}
              </div>
            </div>
            <div className="block sm:hidden lg:block flex-grow" />
            <Icon
              path="res-react-dash-sidebar-card-select"
              className="block sm:hidden lg:block w-5 h-5"
            />
          </button>
        </div>
        {selected === "1" && <BlockManager />}
        <div className="flex-grow" />
      </div>

      {/* <div className="flex-shrink-0 overflow-hidden">
        <div className="flex items-center h-full sm:justify-center xl:justify-start p-2 sidebar-separator-bottom">
          <Image path="mock_faces_8" className="w-8 h-8" />
        </div>
      </div> */}
    </div>
  );
}
