"use client";
import { useThemeArea } from "@/providers/theme/theme-provider";

export default function HeaderTag({
  children,
}: {
  children: React.ReactNode;
  editOn: boolean;
}): JSX.Element {
  const themeArea = useThemeArea("web");
  if (!themeArea) return <></>;
  const hasTransparency = themeArea.theme.headerOptions.includes("transparency");
  return (
    <div
      className={`fixed lg:relative left-0 right-0  lg:top-0 z-50 ${hasTransparency ? "bg-transparent" : "bg-header"}`}>
      {children}
    </div>
  );
}
