"use client";

import { TbFilterSearch } from "react-icons/tb";

import { useWebUiContext, useWebUiDispatch } from "../../providers/webui/web-ui-provider";

export default function SearchButton() {
  const { displaySearch } = useWebUiContext();
  const dispatch = useWebUiDispatch();
  if (!dispatch) return null;

  const handleSearchClick = () => {
    if (displaySearch) {
      dispatch({ type: "displaySearchOff" });
    } else {
      dispatch({ type: "displaySearchOn" });
    }
  };
  return (
    <TbFilterSearch
      onClick={handleSearchClick}
      className={`w-7 h-7 ml-2 cursor-pointer ${displaySearch ? "bg-gray-800" : ""}`}
    />
  );
}
