import(/* webpackMode: "eager", webpackExports: ["SignOut","SignIn","ModeAdmin"] */ "/vercel/path0/apps/web/src/components/auth/auth-components.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Sidebar"] */ "/vercel/path0/apps/web/src/components/sidebar/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/structure/center.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/structure/header-tag.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/structure/search-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/structure/web-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OrderProvider"] */ "/vercel/path0/apps/web/src/providers/order/order-manager-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemesConsumer"] */ "/vercel/path0/apps/web/src/providers/theme/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/providers/web-data/web-data-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebUiProvider"] */ "/vercel/path0/apps/web/src/providers/webui/web-ui-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next@14.2.1_@babel+core@7.24.4_react-dom@18.3.0-canary-670811593-20240322_react@18.3.0-canary-670811593-20240322/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownMenu","DropdownMenuTrigger","Button","DropdownMenuContent","DropdownMenuLabel","DropdownMenuItem"] */ "/vercel/path0/packages/ui/dist/index.js");
