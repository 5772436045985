"use client";

import { templates } from "@/providers/theme/theme-data";
import {
  ThemeTemplateActionType,
  useThemeArea,
  useThemesDispatch,
} from "@/providers/theme/theme-provider";
import { SelectItem } from "@lifizy/ui";
import { useState, type ReactNode } from "react";
import SheetSelector from "./sheet-selector";

export default function TemplateForm({ onClose }: { onClose: () => void }): ReactNode {
  const dispatchTheme = useThemesDispatch();

  const themeArea = useThemeArea("web");
  const [template, setTemplate] = useState(templates[0]);

  if (!themeArea || !dispatchTheme) return null;

  const nextTemplate = () => {
    const index = templates.indexOf(template);
    const action: ThemeTemplateActionType = {
      type: "templateChange",
      themeAreaCode: themeArea.code,
      templateId: "",
    };
    if (index < templates.length - 1) {
      setTemplate(templates[index + 1]);
      action.templateId = templates[index + 1].id;
    } else {
      setTemplate(templates[0]);
      action.templateId = templates[0].id;
    }
    dispatchTheme(action);
  };
  const previousTemplate = () => {
    const index = templates.indexOf(template);
    const action: ThemeTemplateActionType = {
      type: "templateChange",
      themeAreaCode: themeArea.code,
      templateId: "",
    };
    if (index > 0) {
      setTemplate(templates[index - 1]);
      action.templateId = templates[index - 1].id;
    } else {
      setTemplate(templates[templates.length - 1]);
      action.templateId = templates[templates.length - 1].id;
    }
    dispatchTheme(action);
  };
  const onValueChange = (id) => {
    const newTemplate = templates.find((t) => t.id === id);
    if (!newTemplate) return;
    const action: ThemeTemplateActionType = {
      type: "templateChange",
      themeAreaCode: themeArea.code,
      templateId: id,
    };
    setTemplate(newTemplate);
    dispatchTheme(action);
  };
  return (
    <SheetSelector
      value={template.id}
      label="Sélectionnez un modèle de site"
      onValueChange={onValueChange}
      onNext={nextTemplate}
      onPrevious={previousTemplate}
      onClose={onClose}>
      {templates.map(({ id, label }) => (
        <SelectItem key={id} value={id}>
          <span className="break">{label}</span>
        </SelectItem>
      ))}
    </SheetSelector>
  );
}
