"use client";
import { useEditUiDispatch } from "@/providers/edit/edit-ui-provider";
import { getNewItemChoice, ItemChoiceTreeType } from "@lifizy/domain-next";
import { Button } from "@lifizy/ui";
import { ReactNode, useState } from "react";
import { BiSolidMessageSquareEdit } from "react-icons/bi";
import { FaFont, FaRegCalendarAlt } from "react-icons/fa";
import { MdOutlineMenuBook } from "react-icons/md";

import {
  HiOutlinePlus,
  HiOutlineSwitchHorizontal,
  HiOutlineTemplate,
  HiOutlineTrash,
} from "react-icons/hi";
import { IoIosColorPalette } from "react-icons/io";
import { IoReturnUpBackSharp } from "react-icons/io5";
import { TbTemplate } from "react-icons/tb";
import ChoiceForm from "./choice-form";
import TemplateForm from "./template-form";
import ThemeColorForm from "./theme-color-form";
import ThemeFontForm from "./theme-font-form";

export interface MenuWebAdmin {
  description: string;
}
interface AdminMenuItemType {
  title: string;
  component?: ReactNode;
  icon?: ReactNode;
}
interface AdminMenuType {
  title: string;
  description: string;
  code: string;
  icon: ReactNode;
  items: Array<AdminMenuItemType>;
}

export default function AdminBar(): ReactNode {
  const dispatchUi = useEditUiDispatch();

  const [menu, setMenu] = useState<AdminMenuType | null>(null);
  const [item, setItem] = useState<AdminMenuItemType | null>(null);
  const [choice, setChoice] = useState<ItemChoiceTreeType | null>(null);
  const webAdmin: AdminMenuType[] = [
    {
      title: "Design",
      description: "Aspect du site",
      code: "design",
      icon: <TbTemplate className="w-6 h-6" />,
      items: [
        {
          title: "Thème",
          component: <TemplateForm onClose={() => setItem(null)} />,
          icon: <HiOutlineTemplate className="w-6 h-6" />,
        },
        {
          title: "Police",
          component: <ThemeFontForm onClose={() => setItem(null)} />,
          icon: <FaFont className="w-6 h-6" />,
        },
        {
          title: "Couleur",
          component: <ThemeColorForm onClose={() => setItem(null)} />,
          icon: <IoIosColorPalette className="w-6 h-6" />,
        },
      ],
    },
    {
      title: "Modifier la carte",
      description: "Modifier la carte du restaurant",
      code: "menu",
      icon: <MdOutlineMenuBook className="w-6 h-6 " />,
      items: [
        {
          title: "Ajouter une étape",
          component: null,
          icon: <HiOutlinePlus className="w-6 h-6" />,
        },
        {
          title: "Changer l'ordre",
          component: <ThemeFontForm onClose={() => setItem(null)} />,
          icon: <HiOutlineSwitchHorizontal className="w-6 h-6" />,
        },
        {
          title: "Supprimer",
          component: <ThemeColorForm onClose={() => setItem(null)} />,
          icon: <HiOutlineTrash className="w-6 h-6" />,
        },
      ],
    },
    {
      title: "Réservation",
      description: "Réservation",
      code: "booking",
      icon: <FaRegCalendarAlt className="w-6 h-6" />,
      items: [],
    },
  ];

  function onIconClick(item: AdminMenuType | null) {
    dispatchUi({
      type: "changeParameter",
      parameter: "webAdminForm",
      value: item ? item.code : null,
    });
    setMenu(item);
  }
  async function onItemIconClick(item: AdminMenuItemType) {
    if (item && item.title === "Ajouter une étape") {
      setChoice(await getNewItemChoice());
    }
    setItem(item);
  }

  if (item && item.component) return item.component;
  if (item && choice) return <ChoiceForm newChoice={choice} onClose={() => setItem(null)} />;

  if (menu)
    return (
      <div className="bg-slate-900">
        {/* <div className="text-xs px-2 bg-slate-600">{menu.description}</div> */}
        <div className="flex justify-around align-middle">
          <div className="flex flex-col p-1 text-slate-400">
            <Button variant="ghost" size="tag" onClick={() => onIconClick(null)}>
              <IoReturnUpBackSharp className="w-6 h-6" />
            </Button>
            <div className="text-xs text-center">Retour</div>
          </div>
          {menu.items.map(
            (item, index) =>
              item && (
                <div key={index} className="flex flex-col p-1">
                  <Button variant="ghost" size="tag" onClick={() => onItemIconClick(item)}>
                    {item.icon}
                  </Button>
                  <div className="text-xxs text-center">{item.title}</div>
                </div>
              )
          )}
          {menu.items.length === 0 && (
            <div className="pt-2 italic text-sm text-slate-400">
              <BiSolidMessageSquareEdit className="w-4 h-4 inline-block text-white" /> : icon
              indiquant
              <br /> les éléments modifiables
            </div>
          )}
        </div>
      </div>
    );
  return (
    <div className="bg-slate-900 flex justify-around">
      {webAdmin.map((item) => (
        <div key={item.code} className="flex flex-col p-1">
          <Button variant="ghost" className="" size="tag" onClick={() => onIconClick(item)}>
            {item.icon}
          </Button>
          <div className="text-xxs text-center">{item.title}</div>
        </div>
      ))}
    </div>
  );
}
