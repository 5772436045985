import { ThemesConsumer } from "@/providers/theme/theme-provider";
import { Button, Select, SelectContent, SelectTrigger, SelectValue } from "@lifizy/ui";
import { ReactNode } from "react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { IoReturnUpBackSharp } from "react-icons/io5";

interface DropdownSelectorProps {
  children: ReactNode;
  value: string;
  label: string;
  onPrevious: () => void;
  onNext: () => void;
  onValueChange: (value: string) => void;
  actionButton?: ReactNode;
  onClose: () => void;
}
export default function SheetSelector({
  children,
  value,
  label,
  onPrevious,
  onNext,
  onValueChange,
  actionButton,
  onClose,
}: DropdownSelectorProps): ReactNode {
  return (
    <ThemesConsumer areaCode="app">
      <div className="w-full bg-slate-900 text-text">
        <div className="text-xs px-2 bg-slate-800">{label}</div>
        <div className="flex justify-between p-1">
          <Button
            variant="ghost"
            className=""
            onClick={() => {
              onClose();
            }}>
            <IoReturnUpBackSharp className="w-8 h-8" />
          </Button>
          {actionButton && actionButton}
          <div className="grow flex justify-end">
            <Button variant="ghost" size="sm" onClick={onPrevious}>
              <GrFormPrevious className="w-8 h-8" />
            </Button>
            <Select onValueChange={onValueChange} value={value}>
              <SelectTrigger className="">
                <SelectValue placeholder="Sélectionner un modèle" />
              </SelectTrigger>
              <SelectContent className="border-0 bg-background">
                <ThemesConsumer areaCode="app">
                  <div className="bg-background text-text">{children}</div>
                </ThemesConsumer>
              </SelectContent>
            </Select>
            <Button variant="ghost" size="sm" onClick={onNext}>
              <GrFormNext className="w-8 h-8" />
            </Button>
          </div>
        </div>
      </div>
    </ThemesConsumer>
  );
}
