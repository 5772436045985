"use client";
import { useThemeArea } from "@/providers/theme/theme-provider";

export default function Center({
  children,
}: {
  children: React.ReactNode;
  editOn: boolean;
}): JSX.Element {
  const themeArea = useThemeArea("web");
  if (!themeArea) return <></>;
  const hasTransparency = themeArea.theme.headerOptions.includes("transparency");
  return <div className={`flex-grow ${hasTransparency ? "mt-0" : "mt-[40px]"}`}>{children}</div>;
}
