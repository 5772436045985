import { Button } from "@lifizy/ui";
import React from "react";

interface Props {
  // Ajoutez ici les propriétés que vous souhaitez utiliser dans votre composant
}

const BlockManager: React.FC<Props> = () => {
  // Ajoutez ici le code de votre composant
  return (
    <div className="px-3">
      <div className="pb-3 text-xs italic">Aucun block sélectionné</div>
      <div>
        <Button variant="default">Ajouter un block</Button>
      </div>
    </div>
  );
};

export default BlockManager;
